<template>

    <div class="container prefix-live-center mt-4">
        <div class="all_matches_content">
            <div class="sabc_lc_tabs_date" v-if="!(($route.fullPath).includes('match-detail'))">
                <ListingTabs :key="$route.params.date+'-tabs'"></ListingTabs>
                <DateSlider :key="$route.params.date+'-slider'"></DateSlider>
            </div>
            <router-view />
        </div>
    </div>

</template>
<script>
    import DateSlider from '../Livecenter/Common/date-slider';
    import ListingTabs from '../Livecenter/Common/match-listing-tabs';
    export default {
        name: "cricket-lc-home",
        data(){
            return {

            }
        },
        components: {
            DateSlider, ListingTabs
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/cricket.scss';
</style>